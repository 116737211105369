// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { lang } from './config';

const resources = {
  es: {
    translation: {
      "headerLine": "¿Qué contar?",
      "whichSource": "¿Usar una imagen de?",
      "coin": "Moneda",
      "pipe": "Tubo",
      "round-bar": "Barra Redonda",
      "cap": "Tapa de Botella",
      "square-pipe": "Tubo Cuadrado",
      "glass": "Vaso de Papel",
      "bbq-stick": "Palillo de Barbacoa",
      "camera": "Cámara",
      "gallery": "Galería",
      "example": "Imagen de ejemplo",
      "coinDesc": "Las monedas son claramente visibles, no se superponen y el fondo es liso :)",
      "coinUnit": "moneda",
      "pipeDesc": "La parte frontal de los tubos es claramente visible y hay la menor cantidad de otras cosas mezcladas :)",
      "pipeUnit": "tubo",
      "round-barDesc": "La parte frontal de las barras redondas es claramente visible y hay la menor cantidad de otras cosas mezcladas :)",
      "round-barUnit": "barra",
      "square-pipeDesc": "La parte frontal de los tubos cuadrados es claramente visible y hay la menor cantidad de otras cosas mezcladas :)",
      "square-pipeUnit": "tubo",
      "glassDesc": "Los bordes de vidrio son claramente visibles y hay la menor cantidad de otras cosas mezcladas :)",
      "glassUnit": "vidrio",
      "bbq-stickDesc": "La parte frontal de los palillos de barbacoa es claramente visible y hay la menor cantidad de otras cosas mezcladas :)",
      "bbq-stickUnit": "palillo",
      "capDesc": "Las tapas de botella son claramente visibles y hay la menor cantidad de otras cosas mezcladas :)",
      "capUnit": "tapa",
      "reportTitle": "Informe de Resultados",
      "pleaseCheck": "Por favor, verifique el resultado con la imagen, puede que no sea 100% preciso",
      "counted": "Contado",
      "counting": "Contando",
      "addNote": "Agregar Nota",
      "download": "Descargar",
      "success": "Éxito",
      "ok": "Ok",
      "copy": "Copiar",
      "copied": "Copiado",
      "edit": "Editar",
      "cancel": "Cancelar",
      "closeEdit": "Cerrar",
      "noted": "Notado",
      "numberOf": "Número de ",
      "coinTotal": " Valor Total:  ",
      "select": "Seleccionar",
      "tableValue": "Valor",
      "tableQuantity": "Cantidad",
      "tableTotal": "Valor Total",
      "contactUs": "Contáctanos",
      "currencyTitle": "Seleccionar Moneda",
      "currencyDesc": "Si cambia la moneda, las notas se eliminarán.",
      "supportUs": "Apóyanos con Lightning."
    }
  },
  en: {
    translation: {
      "headerLine": "What to count?",
      "whichSource": "Use an image from ?",
      "coin": "Coin",
      "pipe": "Pipe",
      "round-bar": "Round Bar",
      "cap": "Bottle Cap",
      "square-pipe": "Square Pipe",
      "glass": "Paper Glass",
      "bbq-stick": "BBQ Stick",
      "camera": "Camera",
      "gallery": "Gallery",
      "example": "Example Image",
      "coinDesc": "Coins are clearly visible, not overlapping, and the background is plain :)",
      "coinUnit": "Coin",
      "pipeDesc": "The front of the pipes are clearly visible, and there are the least amount of other things mixed in :) ",
      "pipeUnit": "pipe",
      "round-barDesc": "The front of the round bars are clearly visible, and there are the least amount of other things mixed in :) ",
      "round-barUnit": "rebar",
      "capDesc": "The bottle caps are clearly visible, and there are the least amount of other things mixed in :) ",
      "capUnit": "bottle cap",
      "square-pipeDesc": "The front of the square pipes are clearly visible, and there are the least amount of other things mixed in :) ",
      "square-pipeUnit": "pipe",
      "glassDesc": "The glass edges are clearly visible, and there are the least amount of other things mixed in :) ",
      "glassUnit": "glass",
      "bbq-stickDesc": "The front of the BBQ sticks are clearly visible, and there are the least amount of other things mixed in :) ",
      "bbq-stickUnit": "stick",
      "reportTitle": "Result Report",
      "pleaseCheck": "Please check the result with the image, it may not be 100% accurate",
      "counted": "Counted",
      "counting": "Counting",
      "addNote": "Add Note",
      "download": "Download",
      "success": "Success",
      "ok": "Ok",
      "copy": "Copy",
      "copied": "Copied",
      "edit": "Edit",
      "cancel": "Cancel",
      "closeEdit": " Close ",
      "noted": "Noted",
      "numberOf": "Number of ",
      "coinTotal": " Total Value:  ",
      "select": "Select",
      "tableValue": "Value",
      "tableQuantity": "Quantity",
      "tableTotal": "Total Value",
      "contactUs": "Contact Us",
      "currencyTitle": "Select Currency",
      "currencyDesc": "If you change the currency,the notes will be deleted.",
      "supportUs": "Support us with Lightning."
    }
  },
  th: {
    translation: {
      "headerLine": "นับอะไรดี ?",
      "whichSource": "ใช้ภาพจาก ?",
      "coin": "เหรียญ",
      "pipe": "ท่อ",
      "round-bar": "เพลา",
      "cap": "ฝาขวด",
      "square-pipe": "ท่อเหลี่ยมด้านเท่า",
      "glass": "แก้วกระดาษ",
      "bbq-stick": "ไม้เสียบ",
      "camera": "กล้อง",
      "gallery": "คลังภาพ",
      "example": "ภาพตัวอย่าง",
      "coinDesc": "เหรียญชัดๆ ไม่ซ้อนกัน พื้นหลังไม่มีลายนะ :)",
      "coinUnit": "เหรียญ",
      "pipeDesc": "ด้านหน้าของท่อชัดเจน มีอย่างอื่นปะปนน้อยที่สุด :)",
      "pipeUnit": "เส้น",
      "round-barDesc": "ด้านหน้าของเพลาชัดเจน มีอย่างอื่นปะปนน้อยที่สุด :)",
      "round-barUnit": "เส้น",
      "capDesc": "ฝาขวดชัดเจน มีอย่างอื่นปะปนน้อยที่สุด :)",
      "capUnit": "ฝา",
      "square-pipeDesc": "ด้านหน้าของท่อเหลี่ยมด้านเท่าชัดเจน มีอย่างอื่นปะปนน้อยที่สุด :)",
      "square-pipeUnit": "เส้น",
      "glassDesc": "เห็นขอบแก้วชัดเจน มีอย่างอื่นปะปนน้อยที่สุด :)",
      "glassUnit": "แก้ว",
      "bbq-stickDesc": "ด้านหน้าของไม้เสียบชัดเจน มีอย่างอื่นปะปนน้อยที่สุด :)",
      "bbq-stickUnit": "ไม้",
      "reportTitle": "รายงานผล",
      "pleaseCheck": "กรุณาตรวจสอบผลลัพธ์ที่รูปภาพ เพราะอาจจะไม่ถูกต้อง 100%",
      "counted": "นับได้",
      "counting": "กำลังนับ",
      "addNote": "เพิ่มโน๊ต",
      "download": "ดาวน์โหลด",
      "ok": "ตกลง",
      "copy": "คัดลอก",
      "edit": "แก้ไข",
      "cancel": "ยกเลิก",
      "closeEdit": "ปิดแก้ไข",
      "noted": "โน๊ต",
      "numberOf": "จำนวน",
      "coinTotal": "เป็นเงินทั้งหมด",
      "select": "เลือก",
      "tableValue": "มูลค่า",
      "tableQuantity": "จำนวน",
      "tableTotal": "มูลค่ารวม",
      "contactUs": "ติดต่อเรา",
      "currencyTitle": "เลือกสกุลเงิน",
      "currencyDesc": "ถ้าคุณเปลี่ยนค่าเงิน ข้อมูลในโน๊ตจะถูกลบ",
      "supportUs": "สนับสนุนเราด้วย Lightning"
    }
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: lang, // ภาษาที่ใช้เริ่มต้น
    fallbackLng: lang, // ภาษาสำรองในกรณีที่การแปลไม่เจอ
    interpolation: {
      escapeValue: false // React ได้ทำการ escaping แล้ว
    }
  });

export default i18n;