import React, { useState } from 'react';
import {
    Box,
    Button,
    Spacer,
    Card,
    Heading,
    HStack,
    CardBody,
    IconButton,
    VStack,
    Icon,
    Text
} from '@chakra-ui/react';
import { AddIcon, MinusIcon } from "@chakra-ui/icons";
import { FaChartBar } from 'react-icons/fa';
import { calculateTotalNThing, displayUnit } from "../util";
import { PRODUCTS_INFO } from '../constants';
import { useTranslation } from 'react-i18next';

function CommonResult({ nFromService, downloadImage, setTotal, id }) {
    const { t } = useTranslation();
    const [nThing, setNThing] = useState(parseInt(nFromService));

    const decrease = () => {
        if (nThing === 0) return;
        setNThing(nThing - 1);
    }
    
    const increase = () => {
        setNThing(nThing + 1);
    }

    const addThing = () => {
        const storedData = localStorage.getItem(PRODUCTS_INFO[id]?.countKey);
        let things = storedData ? JSON.parse(storedData) : [];
        things.push({ nThing: nFromService });
        localStorage.setItem(PRODUCTS_INFO[id]?.countKey, JSON.stringify(things));
        setTotal(calculateTotalNThing(id))
    }

    return (
        <VStack>
            <HStack>
                <Icon as={FaChartBar} />
                <Heading size='sm'>{t('reportTitle')}</Heading>
            </HStack>
            <Text fontSize='xs'>
                {t('pleaseCheck')}
            </Text>
            <Box></Box>
            <Card borderRadius='xl' p="2" w="100%">
                <CardBody p="1">
                    <HStack mt={1} mb={1}>
                        <Spacer />
                        <Heading color="rgb(64, 64, 64)" fontSize="md">
                            {t('counted')}
                        </Heading>
                        <Heading color="rgb(83, 131, 236)" fontSize="md" marginLeft="8px">
                            {nFromService} {displayUnit(t, id, nFromService)}
                        </Heading>
                        <Spacer />
                    </HStack>
                    <HStack mt={3}>
                        <Spacer />
                        <Button onClick={addThing} width="100px" borderRadius="3xl">{t('addNote')}</Button>
                        <Button onClick={downloadImage} width="100px" borderRadius="3xl">{t('download')}</Button>
                        <Spacer />
                    </HStack>
                </CardBody>
            </Card>
            <Box></Box>
        </VStack>
    )
}

export default CommonResult;