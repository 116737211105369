import React, { useState, useEffect } from 'react';
import {
    Box,
    Button,
    Card,
    CardBody,
    Heading,
    HStack,
    VStack,
    Spacer,
    Icon,
    Text
} from '@chakra-ui/react';

import { useTranslation } from 'react-i18next';
import MixedCoinRow from "./MixedCoinRow";
import { FaChartBar } from 'react-icons/fa';
import { getDataGrouping, calculateTotalValue, calculateTotalN, getCurrencyUnit, displayCurrencyValue, displayUnit } from './util';

function CoinResult({ nCoinFromService, dataFromService, setTotal, setTotalN, downloadImage }) {
    const { t } = useTranslation();
    const currencyUnit = getCurrencyUnit();    
    const [nCoin, setNCoin] = useState(nCoinFromService);
    const [coinList, setCoinList] = useState([
        { n: 10 },
        { n: 100 }
    ]);

    useEffect(() => {
        if (dataFromService) {
            setCoinList(getDataGrouping(dataFromService));
        }
    }, [dataFromService])

    const setValueByRow = (idx, row) => {
        const result = [...coinList];
        result[idx] = row;
        setCoinList(result);
    }

    const getTotalValue = () => {
        if (coinList.some(obj => obj.value === undefined)) {
            return "-";
        }
        return coinList.reduce((total, obj) => total + (obj.value * obj.n), 0);
    }

    const getTotalCoin = () => {
        return coinList.reduce((total, obj) => total + obj.n, 0);
    }

    const addCoin = () => {
        const storedData = localStorage.getItem('coins');
        let coins = storedData ? JSON.parse(storedData) : [];
        coinList.forEach((obj) => {
            coins.push({
                quantity: obj.n,
                value: obj.value
            })
        });
        localStorage.setItem('coins', JSON.stringify(coins));
        setTotal(calculateTotalValue())
        setTotalN(calculateTotalN())
    }

    return (
        <VStack>
            <HStack>
                <Icon as={FaChartBar} />
                <Heading size='sm'>{t('reportTitle')}</Heading>
            </HStack>
            <Text fontSize='xs'>
                {t('pleaseCheck')}
            </Text>
            <Box></Box>
            <Card borderRadius='xl' p="1" w="100%">
                <CardBody p="1">
                    <HStack>
                        <Spacer />
                        <Heading color="rgb(83, 131, 236)" marginLeft="8px" fontSize="md">
                            {getTotalCoin()} {displayUnit(t, 'coin', getTotalCoin())}
                        </Heading>
                        <Spacer />
                        <Text marginLeft="8px" fontSize="md">
                            {t('coinTotal')}
                        </Text>
                        <Spacer />
                        <Heading color="rgb(0, 192, 0)" marginLeft="8px" fontSize="md">
                            {displayCurrencyValue(getTotalValue())} {currencyUnit}
                        </Heading>
                        <Spacer />
                    </HStack>
                </CardBody>
            </Card>
            <Card borderRadius='xl' maxW='sm' p="1">
                <CardBody p="1">
                    <VStack spacing={4}>
                        {
                            coinList.map((item, index) => (
                                <Box key={index}>
                                    <MixedCoinRow
                                        key={index}
                                        groupId={index}
                                        value={item.value}
                                        n={item.n}
                                        setValueByRow={setValueByRow}
                                        color={item.color}
                                    />
                                </Box>
                            ))
                        }
                        <Box></Box>
                        <Box>
                            <HStack>
                                <Button onClick={() => { addCoin() }} width="120px" isDisabled={getTotalValue() === "-"} borderRadius="3xl">{t('addNote')}</Button>
                                <Button onClick={() => { downloadImage() }} width="120px" borderRadius="3xl">{t('download')}</Button>
                            </HStack>
                        </Box>
                    </VStack>
                </CardBody>
            </Card>
            <Box></Box>
        </VStack>
    );
}

export default CoinResult;