import React, { useState, useEffect } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  Box,
  ModalBody,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  IconButton,
  VStack,
  Text,
  Flex
} from '@chakra-ui/react';
import { DeleteIcon, AddIcon, MinusIcon } from '@chakra-ui/icons';
import { calculateTotalValue, calculateTotalN, groupByCoinValue, displayCurrencyValue, getCurrencyUnit, displayUnit } from "./util";
import { useTranslation } from 'react-i18next';

function CoinTable({ total, totalN, setTotal, setTotalN }) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState([]);
  const currencyUnit = getCurrencyUnit();

  useEffect(() => {
    const storedData = localStorage.getItem('coins');
    if (storedData) {
      setData(JSON.parse(storedData));
    }
  }, []);

  const setAllTotal = () => {
    setTotal(calculateTotalValue());
    setTotalN(calculateTotalN());
  }

  const onDelete = (coin) => {
    const newData = data.filter(item => item !== coin);
    setData(newData);
    localStorage.setItem('coins', JSON.stringify(newData));
    setAllTotal();
  };

  const onIncrement = (coin) => {
    const newData = data.map(item => {
      if (item === coin) {
        return { ...item, quantity: item.quantity + 1 };
      }
      return item;
    });
    setData(newData);
    localStorage.setItem('coins', JSON.stringify(newData));
    setAllTotal();
  };

  const onDecrement = (coin) => {
    const newData = data.map(item => {
      if (item === coin) {
        const newQuantity = item.quantity - 1;
        if (newQuantity >= 0) {
          return { ...item, quantity: newQuantity };
        }
      }
      return item;
    });
    setData(newData);
    localStorage.setItem('coins', JSON.stringify(newData));
    setAllTotal();
  };

  const onClose = () => setIsOpen(false);
  const onOpen = () => {
    const storedData = localStorage.getItem('coins');
    if (storedData) {
      setData(JSON.parse(storedData));
    }
    setIsOpen(true);
  }

  const renderTotalCoin = () => {
    const grouping = groupByCoinValue()
    return grouping.map((item, index) => (
      <Flex key={index}>
        <Text marginLeft="24px" width="90px">{item.value} {currencyUnit}</Text>
        <Text marginLeft="24px" width="110px">{item.quantity} {displayUnit(t, 'coin', item.quantity)}</Text>
        <Text marginLeft="24px" width="110px">{displayCurrencyValue(item.totalValue)} {currencyUnit}</Text>
      </Flex>
    ))
  }

  return (
    <VStack>
      <Button onClick={onOpen} size="sm">{t('noted')} ({total})</Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('noted')} ({total} {currencyUnit}, {totalN} {displayUnit(t, 'coin', totalN)})</ModalHeader>
          <ModalCloseButton />
          <ModalBody marginLeft="-24px">
            {renderTotalCoin()}
            <Box height="16px"></Box>
            <Table>
              <Thead>
                <Tr>
                  <Th>{t('tableValue')}</Th>
                  <Th>{t('tableQuantity')}</Th>
                  <Th>{t('tableTotal')}</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                {data.map((coin, index) => (
                  <Tr key={index}>
                    <Td>{coin.value}</Td>
                    <Td>
                      <Flex alignItems="center">
                        <IconButton
                          aria-label="Decrement"
                          icon={<MinusIcon />}
                          size="sm"
                          marginRight="8px"
                          colorScheme='white'
                          color="black"
                          variant='outline'
                          isRound
                          onClick={() => onDecrement(coin)}
                        />
                        <span>{coin.quantity}</span>
                        <IconButton
                          aria-label="Increment"
                          icon={<AddIcon />}
                          size="sm"
                          marginLeft="8px"
                          color="black"
                          colorScheme='white'
                          variant='outline'
                          isRound
                          onClick={() => onIncrement(coin)}
                        />
                      </Flex>
                    </Td>
                    <Td>{displayCurrencyValue(coin.quantity * coin.value)}</Td>
                    <Td>
                      <IconButton aria-label="Delete" icon={<DeleteIcon />} onClick={() => onDelete(coin)} />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </ModalBody>
        </ModalContent>
      </Modal>
    </VStack>
  );
}

export default CoinTable;