import React from 'react';
import {
  ChakraProvider,
  Box,
  VStack,
  Grid,
  extendTheme,
} from '@chakra-ui/react';
import FileAndCameraButtons from './FileAndCameraButtons';

// 2. Add your color mode config
const config = {
  initialColorMode: 'light',
  useSystemColorMode: false,
}

// 3. extend the theme
const theme = extendTheme({
  config,
  components: {
    Button: {
      baseStyle: {
        color: "yellow-dark",
        backgroundColor: "rgb(245, 194, 66)", // RGB value for red
        _hover: {
          backgroundColor: "rgb(245, 194, 66)", // Darker shade on hover
        },
        _active: {
          backgroundColor: "rgba(245, 194, 66, 0.5)", // Darker shade on active
        },
      },
    },
  }
})

function App() {
  return (
    <ChakraProvider theme={theme}>
      <Box textAlign="center" fontSize="xl" bg="blackalpha.200">
        <Grid p={3} marginTop="0px">
          <Box justifySelf="flex-end" />
          <VStack spacing={8}>
            <FileAndCameraButtons />
          </VStack>
        </Grid>
      </Box>
    </ChakraProvider>
  );
}

export default App;
