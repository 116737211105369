import React, { useState, useEffect } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td,
  IconButton,
} from '@chakra-ui/react';
import { DeleteIcon } from '@chakra-ui/icons';
import { calculateTotalNThing, displayUnit } from "../util";
import { PRODUCTS_INFO } from '../constants';
import { useTranslation } from 'react-i18next';
import { lang } from '../config';


function CommonTable({ total, setTotal, id }) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    const storedData = localStorage.getItem(PRODUCTS_INFO[id]?.countKey);
    if (storedData) {
        setData(JSON.parse(storedData));
    }
  }, [id]);

  const onDelete = (pipe) => {
    const newData = data.filter(item => item !== pipe);
    setData(newData);
    localStorage.setItem(PRODUCTS_INFO[id]?.countKey, JSON.stringify(newData));
    setTotal(calculateTotalNThing(id))
  };

  const onClose = () => setIsOpen(false);
  const onOpen = () => {
    const storedData = localStorage.getItem(PRODUCTS_INFO[id]?.countKey);
    if (storedData) {
        setData(JSON.parse(storedData));
    }
    setIsOpen(true);
  }

  return (
    <>
      <Button onClick={onOpen} size="sm">{t('noted')} ({total})</Button>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('noted')} ({total} {displayUnit(t, id, total)})</ModalHeader>
          <ModalCloseButton />
          <ModalBody marginLeft="-24px">
            <Table>
              <Thead>
                <Tr>
                  <Th>{`${t('numberOf')}${t(id)}${lang === 'en' ? 'S': ''}`}</Th>
                  <Th></Th>
                </Tr>
              </Thead>
              <Tbody>
                {data.map((thing, index) => (
                  <Tr key={index}>
                    <Td>
                      {thing.nThing}
                    </Td>
                    <Td>
                      <IconButton aria-label="Delete" icon={<DeleteIcon />} onClick={() => onDelete(thing)} />
                    </Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}

export default CommonTable;