import React, { useEffect, useState, useRef } from 'react';
import {
    Button,
    Box,
    Input,
    Text
} from '@chakra-ui/react';
import Resizer from 'react-image-file-resizer';
import { VStack, HStack, Card, CardBody, Heading } from '@chakra-ui/react'
import { BsImageFill, BsFillCameraFill } from 'react-icons/bs';

import Logo from "./Logo";
import ImageSlider from './ImageSlider';
import CoinResult from './CoinResult';
import CommonResult from './components/CommonResult';
import TopRightMenu from './TopRightMenu';
import Advertise from './Advertise';
import BrandAdvertise from './BrandAdvertise';
import DetectImage from './DetectImage';

import {
  calculateTotalValue,
  calculateTotalN,
  getGroupIdx,
  getColorGroupIdx,
  getCurrentDateTime,
  calculateTotalNThing,
  getCurrency,
} from './util';

import {
  PRODUCTS_INFO
} from './constants';

import {
  API_HOST,
  lang
} from './config'

import { useTranslation } from 'react-i18next';

const getTotal = (inpType) => {
  if (inpType === 'coin') {
    return calculateTotalValue();
  } else {
    return calculateTotalNThing(inpType)
  }
}

const FileAndCameraButtons = () => {

  const { t } = useTranslation();
  const [selectedFile, setSelectedFile] = useState();
  const [resultFile, setResultFile] = useState();
  const [nCoin, setNCoin] = useState();
  const [currency, setCurrency] = useState(getCurrency());
  const [nCoinFromService, setNCoinFromService] = useState();
  const [dataFromService, setDataFromService] = useState(undefined);
  const fileInputRef = React.useRef();
  const [isLoading, setIsLoading] = useState(false);
  const [totalN, setTotalN] = useState(calculateTotalN());
  const [captureBy, setCaptureBy] = useState(undefined);
  const [captureTime, setCaptureTime] = useState(undefined);
  if (localStorage.getItem('type') === 'seed') {
    localStorage.setItem('type', 'coin');
  }
  const [type, setType] = useState(localStorage.getItem('type') || 'coin');
  const [total, setTotal] = useState(getTotal(type));

  const imageRef = useRef();
  const scrollToBottom = () => imageRef.current.scrollIntoView({ behavior: 'smooth' });

  useEffect(() => {
    if (selectedFile) {
        handleSubmitClick();
    }
  // eslint-disable-next-line
  }, [selectedFile])

  useEffect(() => {
    fileInputRef.current.click();
  }, [captureTime])

  useEffect(() => {
    if (dataFromService) {
      renderImage();
    }
  }, [dataFromService])

  const renderImage = async () => {
    const positionAndArea = convertPosition(dataFromService.data);
    setResultFile(await drawCirclesOnImage(positionAndArea));
    setTimeout(() => {
      scrollToBottom();
    }, 300);
  }

  const handleSubmitClick = async () => {
    setIsLoading(true);
    fetch(API_HOST, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ fileContent: selectedFile, type: type, token: localStorage.getItem('token') || undefined }),
    })
    .then(response => response.json())
    .then(async (data) => {
      setDataFromService({
        ...data.data,
        data: JSON.parse(data.data.data),
        gn: data.data.gn ? JSON.parse(data.data.gn) : undefined
      });
      setNCoin(data.data.n);
      setNCoinFromService(data.data.n);
      setIsLoading(false);
    })
    .catch((error) => {
      console.error('Error:', error);
      setIsLoading(false);
    });
  }

  const  drawCirclesOnImage = async (positions) => {
    // สร้างภาพใหม่
    let img = new Image();
    img.src = selectedFile;

    // สร้าง canvas ใหม่
    let canvas = document.createElement('canvas');
    let ctx = canvas.getContext('2d');

    // รอภาพโหลด
    await new Promise((resolve) => {
        img.onload = () => {
            // ตั้งค่าขนาด canvas เท่ากับขนาดภาพ
            canvas.width = img.width;
            canvas.height = img.height;

            // วาดภาพลงบน canvas
            ctx.drawImage(img, 0, 0, img.width, img.height);

            resolve();
        };
    });

    // วาดวงกลมที่ตำแหน่งที่กำหนด
    ctx.lineWidth = 2;  // ความหนาของเส้น
    positions.forEach((pos, idx ) => {
        let x = pos.x * img.width;
        let y = pos.y * img.height;
        let groupIdx = 0;
        if (dataFromService['gn']) {
          groupIdx = getGroupIdx(dataFromService['gn'], pos.x, pos.y);
        }
        if (type === 'coin') {
          let radius = pos.radius * Math.min(img.width, img.height);
          if (idx === 0) {
            ctx.strokeStyle =  getColorGroupIdx(groupIdx);
            ctx.fillStyle = '#00ff00';
          }
          else if (idx === positions.length -1) {
            ctx.strokeStyle =  getColorGroupIdx(groupIdx);
            ctx.fillStyle = '#00ff00';
          } else { 
            ctx.strokeStyle = getColorGroupIdx(groupIdx);
            ctx.fillStyle = getColorGroupIdx(groupIdx);
          }

          let idxStr = (idx+1).toString()
          let fontSize = radius;
          ctx.font = `${fontSize}px Arial`;
          ctx.beginPath();
          ctx.arc(x, y, radius, 0, 2 * Math.PI);
          ctx.stroke();
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          ctx.fillText(idxStr, x, y);
        } else {
          let radius = pos.radius * Math.min(img.width, img.height) * 2 / 2.1;
          let idxStr = (idx+1).toString()
          // วาดวงกลม
          ctx.beginPath();
          ctx.arc(x, y, radius, 0, 2 * Math.PI);
          ctx.fillStyle = 'red';
          ctx.fill();

          // เขียนตัวเลข
          let fontSize = radius+2;
          ctx.font = `${fontSize}px Arial`;
          ctx.fillStyle = 'white';
          ctx.textAlign = 'center';
          ctx.textBaseline = 'middle';
          ctx.fillText(idxStr, x, y);
        }
    });

    // แปลง canvas กลับเป็น base64
    let resultBase64 = 'data:image/jpeg;base64,'+canvas.toDataURL().split(',')[1];
    return resultBase64;
  }

  const handleFileClick = () => {
    setCaptureBy(undefined);
    // eslint-disable-next-line
    setCaptureTime((new Date).getTime());
  }

  const handleCameraClick = () => {
    setCaptureBy("camera");
    // eslint-disable-next-line
    setCaptureTime((new Date).getTime());
  }

  const resizeFile = (file) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                1440,
                1440,
                'JPEG',
                100,
                0,
                (uri) => {
                    resolve(uri);
                },
                'base64'
            );
        });

  const handleFileChange = async (e) => {
    if (e.target?.files[0]) {
      setResultFile(null);
      const file = e.target.files[0];
      const image = await resizeFile(file);
      setSelectedFile(image);
    }
  }

  const handleRequestToken = async () => {
    if (localStorage.getItem('token')) {
      return; 
    } else {
      fetch(API_HOST, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ method: 'request-token', type: type }),
      })
      .then(response => response.json())
      .then(async (data) => {
        localStorage.setItem('token', data.data);
      })
      .catch((error) => {
        
      });
    }
  }

  const convertPosition = (list) => {
    const result = [];
    list.forEach((obj) => {
        result.push({
          x: obj[0],
          y: obj[1],
          area: obj[2] * 100 * obj[3] * 100,
          radius: Math.min(obj[2], obj[3]) / 2
        });
    });
    return result;
  }

  const getImageFileName = (inpType) => {
    return `${PRODUCTS_INFO[inpType]?.downloadFileName}-`;
  }

  const getDescription = (inpType) => {
    return t(`${inpType}Desc`);
  }

  const getSliderImages = (inpType) => {
    return PRODUCTS_INFO[inpType]?.imageUrls;
  }

  const downloadImage = () => {
    const a = document.createElement('a');
    a.href = resultFile;
    a.download = `${nCoinFromService}${getImageFileName(type)}${getCurrentDateTime()}.jpg`; // สามารถเปลี่ยนชื่อไฟล์ที่ต้องการดาวน์โหลด
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <VStack spacing={2}>
        <TopRightMenu total={total} setTotal={setTotal} totalN={totalN} setTotalN={setTotalN} type={type} currency={currency}/>
        <Box height="16px"></Box>
        <Logo type={type} setType={setType} />
        <Box mt='2'>
            <Input
                ref={fileInputRef}
                type="file"
                accept="image/*" 
                capture={captureBy}
                style={{ display: 'none' }}
                onChange={handleFileChange}
            />
            <Box w={340} pl={4} borderWidth='1px' pt={2} pr={2} borderRadius='xl' pb={4}  >
                  <Text fontSize='md' textAlign="left" mb={2}>
                    {t('whichSource')}
                  </Text>
                  <HStack w="auto">
                    <Button
                        leftIcon={<BsFillCameraFill />}
                        onClick={() => {
                          handleRequestToken();
                          handleCameraClick();
                        }}
                        width="50%"
                        isLoading={isLoading && captureBy === "camera"}
                        isDisabled={isLoading && captureBy === undefined}
                        loadingText={t('counting')}
                        borderRadius="3xl"
                    >{t('camera')}</Button>
                    <Button
                        leftIcon={<BsImageFill />}
                        onClick={() => {
                          handleRequestToken();
                          handleFileClick();
                        }}
                        isDisabled={isLoading && captureBy === "camera"}
                        isLoading={isLoading && captureBy === undefined}
                        loadingText={t('counting')}
                        borderRadius="3xl"
                        width="50%"
                    >{t('gallery')}</Button>
                  </HStack>
            </Box>
        </Box>
        <BrandAdvertise />
        <Advertise />            
        <Box></Box>
        <Card variant="filled" borderRadius='2xl'  w="340px" p="1.5">
          <CardBody p="1.5">
        {!resultFile && !selectedFile && (
                <VStack>
                  <Heading size='sm'>{t('example')}</Heading>
                  <Text fontSize='xs'>
                  {getDescription(type)}
                  </Text>
                  <ImageSlider imageUrls={getSliderImages(type)} />
                </VStack>
        )}
        { resultFile && type === 'coin' && (
          <CoinResult
            nCoinFromService={nCoinFromService}
            dataFromService={dataFromService}
            setTotal={setTotal}
            setTotalN={setTotalN}
            downloadImage={downloadImage}
          />
        ) }
        { resultFile && type !== 'coin' && (
          <CommonResult
            nFromService={nCoinFromService}
            dataFromService={dataFromService}
            setTotal={setTotal}
            setTotalN={setTotalN}
            downloadImage={downloadImage}
            id={type}
          />
        ) }
        {selectedFile &&  !resultFile && (
            <Box maxW='sm' borderWidth='1px' borderRadius='lg' overflow='hidden'>
                <img src={selectedFile} alt='Your uploaded' />
            </Box>
        )}
        {resultFile && (
            <Box maxW='sm' borderWidth='1px' borderRadius='lg' overflow='hidden' ref={imageRef}>
                {
                  type === 'coin' ?
                    <img src={resultFile} alt='Your uploaded' /> :
                    <DetectImage 
                      imageUrl={selectedFile}
                      initialPositions={dataFromService.data}
                      setN={setNCoinFromService}
                      setResultFile={setResultFile}
                    />
                }
            </Box>
        )}
          </CardBody>
        </Card>
    </VStack>
  );
}

export default FileAndCameraButtons;