import { PRODUCTS_INFO, CURRENCY_VALUE_DATA } from "./constants";
import { lang } from "./config";

export const calculateTotalValue = () => {
    const storedData = localStorage.getItem('coins');
    if (storedData) {
      const coins = JSON.parse(storedData);
      return coins.reduce((total, coin) => total + (coin.quantity * coin.value), 0);
    }
    return 0;
}

export const calculateTotalNSeed = () => {
  const storedData = localStorage.getItem('seeds');
  if (storedData) {
    const coins = JSON.parse(storedData);
    return coins.reduce((total, seed) => total + parseInt(seed.nSeed), 0);
  }
  return 0;
}

export const calculateTotalNPipe = () => {
  const storedData = localStorage.getItem('pipes');
  if (storedData) {
    const coins = JSON.parse(storedData);
    return coins.reduce((total, pipe) => total + parseInt(pipe.nPipe), 0);
  }
  return 0;
}

export const calculateTotalNThing = (id) => {
  const storedData = localStorage.getItem(PRODUCTS_INFO[id]?.countKey);
  if (storedData) {
    const things = JSON.parse(storedData);
    return things.reduce((total, thing) => total + (thing?.nThing | 0), 0);
  }
  return 0;
}

export const calculateTotalN = () => {
  const storedData = localStorage.getItem('coins');
  if (storedData) {
    const coins = JSON.parse(storedData);
    return coins.reduce((total, coin) => total + coin.quantity, 0);
  }
  return 0;
}

export const groupByCoinValue = () => {
  let arr = localStorage.getItem('coins');
  if (!arr) {
    return [];
  }
  arr = JSON.parse(arr);
  const result = {};
  for(let i = 0; i < arr.length; i++) {
      const value = arr[i].value;

      if (!result[value]) {
          result[value] = {
              value: value,
              quantity: 0,
              totalValue: 0,
          };
      }

      result[value].quantity += arr[i].quantity;
      result[value].totalValue += arr[i].value * arr[i].quantity;
  }

  return Object.values(result);
}

const findIndex = (array, x, y) => {
  for(let i = 0; i < array.length; i++) {
      let { xmin, xmax, ymin, ymax } = array[i];
      if(x >= xmin - 0.001 && x <= xmax + 0.001 && y >= ymin - 0.001 && y <= ymax + 0.001) {
          return i;
      }
  }
  return -1; // Return -1 if x is not within any range
}

export const getGroupIdx = (group, x, y) => {
  return findIndex(group, x, y)
}

const convertPosition = (list) => {
  const result = [];
  list.forEach((obj) => {
      result.push({
        x: obj[0],
        y: obj[1],
        area: obj[2] * 100 * obj[3] * 100,
        radius: Math.min(obj[2], obj[3]) / 2
      });
  });
  return result;
}

export const getDataGrouping = (data) => {
  const group = data.gn;
  const coinData = convertPosition(data.data);
  if (!group) {
    return [{ n: coinData.length, color: getColorGroupIdx(0) }];
  }
  else {
    let n = 0;
    const groupTotal = {};
    for(let i = 0; i < coinData.length; i++) {
      let obj = coinData[i];
      const groupIdx = getGroupIdx(group, obj.x, obj.y);
      if (!groupTotal[''+groupIdx]) {
        groupTotal[''+groupIdx] = 1;
        n++;
      } else {
        groupTotal[''+groupIdx] += 1;
        n++;
      }
    }
    if (n !== coinData.length) {
      return [{ n: coinData.length }];
    } else {
      const keys = Object.keys(groupTotal);
      const result = [];
      keys.forEach((val) => {
        result.push({
          n: groupTotal[val],
          color: getColorGroupIdx(parseInt(val))
        });
      });
      return result
    }
  }
}

export const getColorGroupIdx = (groupId) => {
  if (groupId === 1) {
    return '#d23759';
  } else if (groupId === 2) {
    return '#964c77';
  } else if (groupId === 3) {
    return '#76E4F7';
  } else if (groupId === -1) {
    return '#76E4F7';
  } else {
    return '#FFFF44';
  }
}

export const getCurrentDateTime = () => {
  const now = new Date();
  const day = String(now.getDate()).padStart(2, '0');
  const month = String(now.getMonth() + 1).padStart(2, '0'); // January is 0!
  const year = now.getFullYear();

  const hours = String(now.getHours()).padStart(2, '0');
  const minutes = String(now.getMinutes()).padStart(2, '0');

  return `${day}${month}${year}-${hours}${minutes}`;
}

export const callServiceAdvertise = async (name) => {
  fetch('https://3en8756bxi.execute-api.ap-southeast-1.amazonaws.com/beta/', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ method: 'advertise', name }),
    })
    .then(response => response.json())
    .then(async (data) => {})
    .catch((error) => {});
}

export const getCurrency = () => {
  return localStorage.getItem('selectedCurrency') || (lang === 'th' ? 'THB' : 'USD')
}

export const getValuesOfCurrency = () => {
  return CURRENCY_VALUE_DATA[getCurrency()];
}

export const getCurrencyUnit = () => {
  const currency = getCurrency();
  if (lang === 'th' && currency == 'THB') {
    return 'บาท';
  } else {
      return currency
  }
}

export const displayCurrencyValue = (input, maxDecimalPlaces = 2) => {
  if (input === undefined || input === null || input === '') {
    return '-';
  }

  let result = parseFloat(input);
  if (isNaN(result)) {
      return '-';
  }

  if (Number.isInteger(result)) {
      return result.toString();
  } else {
      let decimalPart = result.toString().split(".")[1];
      if (decimalPart && decimalPart.length > maxDecimalPlaces) {
          return result.toFixed(maxDecimalPlaces);
      } else {
          return result.toString();
      }
  }
}

export const displayUnit = (t, id, n) => {
  if (lang === 'th') {
    return t(`${id}Unit`);
  } else {
    return t(`${id}Unit`) + (n > 1 ? 's' : '');
  }
}