import React, { useEffect, useState } from "react";
import { Box, Image, Text, LinkBox, LinkOverlay } from "@chakra-ui/react";

const Advertise = () => {
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('https://3en8756bxi.execute-api.ap-southeast-1.amazonaws.com/beta/', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ method: 'link-advertise' }),
        });
        if (response.ok) {
          const result = await response.json();
          if (result.statusCode === 200) {
            setData(result.body);
          }
        }
      } catch (error) {
        console.error("Error fetching data: ", error);
      }
    };
    fetchData();
  }, []);

  if (!data) return null;

  return (
    <LinkBox as="article" display="flex" alignItems="center" cursor="pointer"
        borderWidth='1px' borderRadius='3xl'
               paddingLeft='24px' paddingRight='16px' 
               paddingTop='8px' paddingBottom='8px'>
      <Image
        src={`data:image/jpeg;base64,${data.base64Image}`}
        alt={data.description}
        boxSize="40px"
        mr={4}
        borderRadius="8px"
      />
      <Box>
        <Text fontSize='sm' width="250px" textAlign="left">
          <LinkOverlay href={data.advertiseUrl} isExternal>
            {data.description}
          </LinkOverlay>
        </Text>
      </Box>
    </LinkBox>
  );
};

export default Advertise;
