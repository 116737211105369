import React, { useState } from 'react';
import { Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Select, Text } from '@chakra-ui/react';
import { lang } from './config';
import { CURRENCY_DATA } from './constants';
import { useTranslation } from 'react-i18next';

const CurrencySelectorButton = ({ currency }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCurrency, setSelectedCurrency] = useState('');

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  const handleSave = () => {
    if (selectedCurrency) {
      localStorage.setItem('selectedCurrency', selectedCurrency);
      if (selectedCurrency === currency) {
        closeModal();
        return;
      } else {
        localStorage.removeItem("coins");
        window.location.reload();
      }
    }
  };

  const getDisplayCurrency = () => {
    if (lang === 'th' && currency == 'THB') {
        return 'บาท';
    } else {
        return currency
    }
  }

  return (
    <>
      <Button size="sm" marginRight="8px" onClick={openModal}>{getDisplayCurrency()}</Button>

      <Modal isOpen={isOpen} onClose={closeModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t('currencyTitle')}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text fontSize='sm' mb={4} >{t('currencyDesc')}</Text>
            <Select placeholder={t('currencyTitle')} onChange={(e) => setSelectedCurrency(e.target.value)} defaultValue={currency}>
              {CURRENCY_DATA.map(currency => (
                <option key={currency.code} value={currency.code}>
                  {currency.name_en} ({currency.code})
                </option>
              ))}
            </Select>
          </ModalBody>

          <ModalFooter>
            <Button variant="ghost" mr={3} onClick={handleSave}>{t('ok')}</Button>
            <Button onClick={closeModal}>{t('cancel')}</Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CurrencySelectorButton;