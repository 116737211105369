import React, { useState } from 'react';
import { Box, Select, Text, Flex, Spacer, IconButton, Circle } from "@chakra-ui/react";
import { AddIcon, MinusIcon } from "@chakra-ui/icons";
import { getValuesOfCurrency, getCurrencyUnit, displayCurrencyValue } from "./util";
import { useTranslation } from 'react-i18next';

const MixedCoinRow = ({ groupId, value, n, setValueByRow, color}) => {
  const { t } = useTranslation();
  const groupColor = color === "#FFFF44" ? "#D69E2E": color;
  const currencyValues = getValuesOfCurrency();
  const currencyUnit = getCurrencyUnit();
  const decrease = () => {
    if (n === 0) return;
    setValueByRow(groupId, {
      value,
      color,
      n: --n
    });
  }

  const increase = () => {
    setValueByRow(groupId, {
      value,
      color,
      n: ++n
    });
  }

  const handleValueChange = (e) => {
    setValueByRow(groupId, {
      value: e.target.value || undefined ,
      color,
      n
    });
  }

  return (
    <Box alignItems='center' display='flex' w="300px">
      <Spacer />
      <Select placeholder={t('select')} onChange={handleValueChange} marginRight="8px" width="100px" fontSize="sm">
      {currencyValues.map((currencyValue) => (
          <option value={currencyValue}> {currencyValue} {currencyUnit === 'บาท' ? currencyUnit : ''}</option>
      ))}
      </Select>
      <Spacer />
      <IconButton size="sm" color={groupColor} icon={<MinusIcon />} borderRadius="full" onClick={decrease} aria-label="Decrease" />
      <Box px={2} width="45px"><Text color={groupColor} fontSize="md">{n}</Text></Box>
      <IconButton size="sm" color={groupColor}  icon={<AddIcon />} borderRadius="full" onClick={increase} aria-label="Increase" />
      <Box px={2} width="100px">
        <Text color="rgb(128, 128, 128)" fontSize="md">
          { value && n ? (`${displayCurrencyValue(n * value)} ${currencyUnit}`) : "" }
        </Text>
      </Box>
      <Spacer />
    </Box>
  );
}

export default MixedCoinRow;