import React, { useState, useRef, useEffect } from 'react';
import { Box, Button, HStack } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

function DetectImage({ imageUrl, initialPositions, setN, setResultFile, sourceRef }) {
  const { t } = useTranslation();
  const [positions, setPositions] = useState(initialPositions);
  const [originalPositions, setOriginalPositions] = useState(initialPositions);
  const [editing, setEditing] = useState(false);
  const canvasRef = useRef(sourceRef);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');
    const image = new window.Image();
    image.src = imageUrl;

    image.onload = () => {
      canvas.width = image.width;
      canvas.height = image.height;
      context.drawImage(image, 0, 0, image.width, image.height);
      drawPositions();
    };

    const drawPositions = () => {
      positions.forEach((position, index) => {
        const x = position[0] * canvas.width - (position[2] * canvas.width / 2);
        const y = position[1] * canvas.height - (position[3] * canvas.height / 2);
        const width = position[2] * canvas.width;
        const height = position[3] * canvas.height;
        const centerX = x + (width / 2);
        const centerY = y + (height / 2);
        const radius = Math.min(width, height) / 3;

        context.beginPath();
        context.arc(centerX, centerY, radius, 0, 2 * Math.PI);
        context.fillStyle = 'rgba(255, 0, 0, 0.5)';
        context.fill();

        let fontSize = radius + 2;
        context.font = `${fontSize}px Arial`;
        context.fillStyle = 'white';
        context.textAlign = 'center';
        context.textBaseline = 'middle';
        context.fillText(index + 1, centerX, centerY);
      });

      setResultFile('data:image/jpeg;base64,'+canvas.toDataURL().split(',')[1]);

      // แสดงจำนวนวงกลมทั้งหมดบนด้านซ้ายบน
      // context.font = '30px Arial';
      // context.fillStyle = 'white';
      // context.textAlign = 'left';
      // context.fillText('นับได้: ' + positions.length, 20, 50);
    };

    const handleCanvasClick = (event) => {
      if (!editing) return;

      const rect = canvas.getBoundingClientRect();
      const scaleX = canvas.width / rect.width;
      const scaleY = canvas.height / rect.height;
      const x = (event.clientX - rect.left) * scaleX;
      const y = (event.clientY - rect.top) * scaleY;

      let averageWidth = positions.reduce((sum, pos) => sum + pos[2], 0) / positions.length;
      let averageHeight = positions.reduce((sum, pos) => sum + pos[3], 0) / positions.length;

      const newPositions = positions.filter((pos) => {
        const posX = pos[0] * canvas.width;
        const posY = pos[1] * canvas.height;
        const dist = Math.sqrt(Math.pow(posX - x, 2) + Math.pow(posY - y, 2));
        return dist > pos[3] * canvas.height / 2;
      });

      if (newPositions.length === positions.length) {
        const newPosition = [x / canvas.width, y / canvas.height, averageWidth, averageHeight];
        newPositions.push(newPosition);
      }

      setPositions(newPositions);
      context.clearRect(0, 0, canvas.width, canvas.height);
      context.drawImage(image, 0, 0, image.width, image.height);
      drawPositions();

      setN(newPositions.length);
    };

    canvas.addEventListener('click', handleCanvasClick);
    return () => canvas.removeEventListener('click', handleCanvasClick);
  }, [imageUrl, positions, editing]);

  const handleCancel = () => {
    setPositions(originalPositions);
    setN(originalPositions.length);
    setEditing(false);
  };

  return (
    <Box position="relative">
      {positions.length > 0 && <HStack>
        <Button 
          backgroundColor="rgba(255, 255, 255, 0.5)"
          size="xs" position="absolute" right="4px" top="4px" onClick={() => { 
          setEditing(!editing);
          setOriginalPositions(positions)
        }}>
          {editing ? t('closeEdit') : t('edit')}
        </Button>
        {editing && (
          <Button 
          backgroundColor="rgba(255, 255, 255, 0.5)"
          size="xs" position="absolute" right="70px" top="4px" onClick={handleCancel}>
            {t('cancel')}
          </Button>
        )}
      </HStack>}
      <canvas ref={canvasRef} style={{ width: '100%', height: 'auto' }} />
    </Box>
  );
}

export default DetectImage;
