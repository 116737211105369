import { Box, Image } from "@chakra-ui/react";
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

const ImageSlider = ({ imageUrls }) => {
    return (
        <Box overflow="hidden" width="320px" height="400px" >
            <Carousel autoPlay infiniteLoop useKeyboardArrows showThumbs={false}>
                {imageUrls.map((imageUrl, index) => (
                    <div key={index}>
                        <Image src={imageUrl} alt={`Slide image ${index + 1}`} objectFit="cover" height="400px" width="200px" />
                    </div>
                ))}
            </Carousel>
        </Box>
    );
};

export default ImageSlider;