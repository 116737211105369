import React from "react";
import { Button, Box, Text, Heading, HStack, VStack } from "@chakra-ui/react";
import ProductTabBar from "./components/ProductTabBar";
import { useTranslation } from 'react-i18next';

const CircleRow = (props) => {
  const { t } = useTranslation();
  const handleChange = (value) => {
    props.setType(value);
    localStorage.setItem('type', value);
    window.location.reload();
  };

  return (
    <VStack spacing={4}>
      <HStack>
        <Box
          borderRadius="50%"
          width="12px"
          height="12px"
          backgroundColor="rgb(241, 191, 66)"
          marginRight="3px"
        />
        <Box
          borderRadius="50%"
          width="35px"
          height="35px"
          backgroundColor="rgb(241, 191, 66)"
        />
        <Box
          borderRadius="50%"
          width="12px"
          height="12px"
          backgroundColor="rgb(241, 191, 66)"
          marginTop="12px"
          marginLeft="3px"
        />
        <Box>
          <Heading marginLeft="8px" size="xl">
            Mr.Count
          </Heading>
        </Box>
      </HStack>
      <Box w={340} pl={4} borderWidth='1px' pt={2} pr={2} borderRadius='xl' >
        <Heading fontSize='md' textAlign="left" mb={1}>
              {t('headerLine')}
        </Heading>
        <ProductTabBar />
      </Box>
    </VStack>
  );
}

export default CircleRow;