
import imageSrc1 from './images/ex1.jpg';
import imageSrc2 from './images/ex2.jpg';
import imageSrc3 from './images/ex3.jpg';
import imageSeedSrc1 from './images/seed1.jpg';
import imageSeedSrc2 from './images/seed2.jpg';
import imageSeedSrc3 from './images/seed3.jpg';
import imagePipeSrc1 from './images/pipe1.jpg';
import imagePipeSrc2 from './images/pipe2.jpg';
import imageCapSrc1 from './images/cap1.jpg';
import imageCapSrc2 from './images/cap2.jpg';
import imageSquarePipeSrc1 from './images/square-pipe1.jpg';
import imageSquarePipeSrc2 from './images/square-pipe2.jpg';
import imageBBQStickSrc1 from './images/bbq-stick1.jpg';
import imageBBQStickSrc2 from './images/bbq-stick2.jpg';
import imageRoundBarSrc1 from './images/roundbar1.jpg';
import imageGlass from './images/glass.jpg';

export const COIN_ID = 'coin'
export const PIPE_ID = 'pipe'
export const ROUND_BAR = 'round-bar'
export const GLASS_ID = 'glass'
export const SEED_ID = 'seed'
export const CAP = 'cap'
export const SQUARE_PIPE_ID = 'square-pipe'
export const BBQ_STICK_ID = 'bbq-stick'

export const PRODUCT_ID_LIST  = [COIN_ID, PIPE_ID, ROUND_BAR, BBQ_STICK_ID, CAP, SQUARE_PIPE_ID, GLASS_ID]

export const PRODUCTS_INFO = {
    [COIN_ID] : {
        description: 'เหรียญชัดๆ ไม่ซ้อนกัน พื้นหลังไม่มีลายนะ :)',
        nText: 'จำนวนเหรียญ',
        downloadFileName: 'COINS',
        unit: 'เหรียญ',
        countKey: 'coins',
        imageUrls : [
            imageSrc1,
            imageSrc2,
            imageSrc3
        ]
    },
    [PIPE_ID] : {
        description: 'ด้านหน้าของท่อชัดเจน มีอย่างอื่นปะปนน้อยที่สุด :)',
        nText: 'จำนวนท่อ',
        downloadFileName: 'PIPES',
        unit: 'เส้น',
        countKey: 'pipes',
        imageUrls : [
            imagePipeSrc1,
            imagePipeSrc2
        ]
    },
    [ROUND_BAR] : {
        description: 'ด้านหน้าของเพลาชัดเจน มีอย่างอื่นปะปนน้อยที่สุด :)',
        nText: 'จำนวนเพลา',
        downloadFileName: 'ROUNDBARS',
        unit: 'เส้น',
        countKey: 'roundbars',
        imageUrls : [
            imageRoundBarSrc1
        ]
    },
    [GLASS_ID] : {
        description: 'เห็นขอบแก้วซ้อนกันชัดเจน มีอย่างอื่นปะปนน้อยที่สุด :)',
        nText: 'จำนวนแก้ว',
        downloadFileName: 'GLASSES',
        unit: 'ใบ',
        countKey: 'glasses',
        imageUrls : [
            imageGlass
        ]
    },
    [BBQ_STICK_ID] : {
        description: 'ด้านหน้าของไม้ชัดเจน มีอย่างอื่นปะปนน้อยที่สุด :)',
        nText: 'จำนวนไม้',
        downloadFileName: 'ฺBBQSTICKS',
        unit: 'ไม้',
        countKey: 'bbqsticks',
        imageUrls : [
            imageBBQStickSrc1,
            imageBBQStickSrc2
        ]
    },
    [SEED_ID] : {
        description: 'เมล็ดชัดๆ วางไม่ติดกัน พื้นหลังไม่มีลายนะ :)',
        nText: 'จำนวนเมล็ด',
        downloadFileName: 'SEEDS',
        unit: 'เมล็ด',
        countKey: 'seeds',
        imageUrls: [
            imageSeedSrc1,
            imageSeedSrc2,
            imageSeedSrc3
        ]
    },
    [CAP] : {
        description: 'ขอฝาขวดชัดๆ พื้นหลังไม่มีลายนะ :)',
        nText: 'จำนวนฝาขวด',
        downloadFileName: 'CAP',
        unit: 'ฝา',
        countKey: 'cap',
        imageUrls: [
            imageCapSrc1,
            imageCapSrc2
        ]
    },
    [SQUARE_PIPE_ID] : {
        description: 'ด้านหน้าของท่อชัดเจน มีอย่างอื่นปะปนน้อยที่สุด :)',
        nText: 'จำนวนท่อ',
        downloadFileName: 'SUARE-PIPES',
        unit: 'เส้น',
        countKey: 'square-pipes',
        imageUrls : [
            imageSquarePipeSrc1,
            imageSquarePipeSrc2
        ]
    },
}


export const CURRENCY_VALUE_DATA = {
    "THB": [1, 2, 5, 10],
    "USD": [0.01, 0.05, 0.1, 0.25, 0.5, 1],
    "EUR": [0.01, 0.02, 0.05, 0.1, 0.2, 0.5, 1, 2],
    "JPY": [1, 5, 10, 50, 100, 500],
    "GBP": [0.01, 0.02, 0.05, 0.1, 0.2, 0.5, 1, 2],
    "AUD": [0.05, 0.1, 0.2, 0.5, 1, 2],
    "CAD": [0.05, 0.1, 0.25, 0.5, 1, 2],
    "CHF": [0.05, 0.1, 0.2, 0.5, 1, 2, 5],
    "CNY": [0.1, 0.5, 1],
    "SEK": [1, 2, 5, 10],
    "NZD": [0.1, 0.2, 0.5, 1, 2],
    "MXN": [0.5, 1, 2, 5, 10, 20],
    "SGD": [0.05, 0.1, 0.2, 0.5, 1],
    "HKD": [0.1, 0.2, 0.5, 1, 2, 5, 10],
    "NOK": [1, 5, 10, 20],
    "KRW": [1, 5, 10, 50, 100, 500],
    "TRY": [0.05, 0.1, 0.25, 0.5, 1],
    "RUB": [1, 2, 5, 10],
    "INR": [0.5, 1, 2, 5, 10],
    "BRL": [0.05, 0.1, 0.25, 0.5, 1],
    "ZAR": [0.1, 0.2, 0.5, 1, 2, 5]
  };


export const CURRENCY_DATA = [
    { code: "THB", name_en: "Baht", name_th: "บาท" },
    { code: "USD", name_en: "United States Dollar", name_th: "ดอลลาร์สหรัฐ" },
    { code: "EUR", name_en: "Euro", name_th: "ยูโร" },
    { code: "JPY", name_en: "Japanese Yen", name_th: "เยนญี่ปุ่น" },
    { code: "GBP", name_en: "British Pound Sterling", name_th: "ปอนด์สเตอร์ลิงอังกฤษ" },
    { code: "AUD", name_en: "Australian Dollar", name_th: "ดอลลาร์ออสเตรเลีย" },
    { code: "CAD", name_en: "Canadian Dollar", name_th: "ดอลลาร์แคนาดา" },
    { code: "CHF", name_en: "Swiss Franc", name_th: "ฟรังก์สวิส" },
    { code: "CNY", name_en: "Chinese Yuan", name_th: "หยวนจีน" },
    { code: "SEK", name_en: "Swedish Krona", name_th: "โครนาสวีเดน" },
    { code: "NZD", name_en: "New Zealand Dollar", name_th: "ดอลลาร์นิวซีแลนด์" },
    { code: "MXN", name_en: "Mexican Peso", name_th: "เปโซเม็กซิโก" },
    { code: "SGD", name_en: "Singapore Dollar", name_th: "ดอลลาร์สิงคโปร์" },
    { code: "HKD", name_en: "Hong Kong Dollar", name_th: "ดอลลาร์ฮ่องกง" },
    { code: "NOK", name_en: "Norwegian Krone", name_th: "โครนนอร์เวย์" },
    { code: "KRW", name_en: "South Korean Won", name_th: "วอนเกาหลีใต้" },
    { code: "TRY", name_en: "Turkish Lira", name_th: "ลีราตุรกี" },
    { code: "RUB", name_en: "Russian Ruble", name_th: "รูเบิลรัสเซีย" },
    { code: "INR", name_en: "Indian Rupee", name_th: "รูปีอินเดีย" },
    { code: "BRL", name_en: "Brazilian Real", name_th: "เรียลบราซิล" },
    { code: "ZAR", name_en: "South African Rand", name_th: "แรนด์แอฟริกาใต้" },
  ];