import React, { useState } from 'react';
import {
    Box,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Button,
    VStack,
    IconButton,
    Icon
} from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import { MdLanguage } from "react-icons/md";
import { FaFacebook, FaTwitter, FaYoutube, FaTiktok } from 'react-icons/fa';
import CommonTable from './components/CommonTable';
import CoinTable from './CoinTable';
import {
  callServiceAdvertise
} from './util';
import CurrencySelectorButton from './CurrencySelectorButton';
import { lang } from './config';
import { useTranslation } from 'react-i18next';

  
  const TopRightMenu = ({ total, totalN, setTotal, setTotalN, type, currency }) => {
    const { t } = useTranslation();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [activeMenu, setActiveMenu] = useState('');

    const handleMenuClick = (menuName) => {
        setActiveMenu(menuName);
        onOpen();
    }

    const handleLanguageClick = (menuName) => {
      if (menuName === 'en') {
        window.location.href = "https://mrcount.co/en";
      } else if(menuName === 'es') {
        window.location.href = "https://mrcount.co/es";
      } else {
        window.location.href = "https://mrcount.co";
      }
    }

    const openNewTab = (url) => {
      const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
      if (newWindow) newWindow.opener = null;
  }

    return (
      <Box display="flex" justifyContent="flex-end" alignItems="flex-end" width="100%">
        <Menu>
          <MenuButton
            as={IconButton}
            aria-label="Options"
            icon={<Icon as={MdLanguage} w={5} h={5} />} // ปรับขนาดไอคอนที่นี่
            size="sm"
            mr="8px"
          />
          <MenuList>
            <MenuItem fontSize="md" onClick={() => handleLanguageClick('en')}>English</MenuItem>
            <MenuItem fontSize="md" onClick={() => handleLanguageClick('es')}>Español</MenuItem>
            <MenuItem fontSize="md" onClick={() => handleLanguageClick('th')}>ไทย</MenuItem>
          </MenuList>
        </Menu>
        { type === 'coin' && <CurrencySelectorButton currency={currency} /> }
        { lang == 'th' && <Button onClick={() => {
          openNewTab("https://www.dhammalife.net");
          callServiceAdvertise("dhammalife");
        }} size="sm" marginRight="8px" >ธรรมะดีๆ</Button> }
        { type === 'coin' && <CoinTable total={total} setTotal={setTotal} totalN={totalN} setTotalN={setTotalN}/> }
        { type !== 'coin' && <CommonTable total={total} setTotal={setTotal} id={type} /> }
        <Box width="8px"></Box>
        <Menu>
          <MenuButton
            as={IconButton}
            aria-label="Options"
            icon={<HamburgerIcon />}
            size="sm"
          />
          <MenuList>
            <MenuItem fontSize="md" onClick={() => handleMenuClick('ติดต่อเรา')}>{t('contactUs')}</MenuItem>
          </MenuList>
        </Menu>
        <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{activeMenu === 'ติดต่อเรา' ? t('contactUs') : activeMenu}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {activeMenu === 'ติดต่อเรา' ? (
              <VStack spacing={4}>
                <Button colorScheme='white' variant='outline' leftIcon={<FaTiktok />} width="224px"  onClick={() => window.open("https://www.tiktok.com/@mrcount.co", "_blank")}>TikTok</Button>
                <Button colorScheme='white' variant='outline' leftIcon={<FaFacebook />} width="224px"  onClick={() => window.open("https://www.facebook.com/mrcount.co", "_blank")}>Facebook</Button>
                <Button colorScheme='white' variant='outline' leftIcon={<FaYoutube />} width="224px"  onClick={() => window.open("https://www.youtube.com/@mrcount-co", "_blank")}>YouTube</Button>
                <Button colorScheme='white' variant='outline' leftIcon={<FaTwitter />} width="224px" onClick={() => window.open("https://twitter.com/tweetinsightth", "_blank")}>Twitter</Button>
            </VStack>
            ) : (
              `คุณกำลังดู ${activeMenu}`
            )}
          </ModalBody>

          <ModalFooter>
          </ModalFooter>
        </ModalContent>
      </Modal>
      </Box>
    );
  };
  
  export default TopRightMenu;
  